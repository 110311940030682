import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import Wc from '@material-ui/icons/Wc';
import Cake from '@material-ui/icons/Cake';
import Assignment from '@material-ui/icons/Assignment';

class DogCard extends Component {
    calculateAge(birthDateStr){
        const today = new Date();
        const birthDate = new Date(birthDateStr);
        var years = today.getFullYear() - birthDate.getFullYear();
        var months = today.getMonth() - birthDate.getMonth();
        if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())){
            years--;
            months += 12;
        }
        var text = "";
        if(years !== 0){
            text += ""+years+" jaar "
        }
        if(months !== 0){
            text += ""+months+" maand"
        }
        return text;
    }
    
    render() {
        const dog = this.props.dog;
        return (
            <Grid item key={dog.dogId} xs={12} sm={6} md={4}>
                <Card className="card">
                <CardMedia
                    className="cardmedia"
                    image={dog.primaryImage}
                    title={dog.name}
                />
                {dog.state === "adopted" &&
                    <div className="adopted">
                        <h2 className="adoptedTitle">Geadopteerd</h2>
                    </div>
                }
                {dog.state === "option" &&
                    <div className="option">
                        <h2 className="optionTitle">Gereserveerd</h2>
                    </div>
                }
                <CardContent className="cardContent">
                    <Typography gutterBottom variant="h5" component="h2" className="notranslate">
                    {dog.name}
                    </Typography>
                    <Typography className="iconAndText">
                        <Wc style={{marginRight: '5px'}}/>
                        {dog.gender === "female" ? "teef" :
                            dog.gender === "male" ? "reu" : "onbekend"}
                    </Typography>
                    <Typography className="iconAndText">
                        <Cake style={{marginRight: '5px', marginBottom: '5px'}}/>
                        {this.calculateAge(dog.birthdate)}
                    </Typography>
                    {
                        (dog.weight && dog.weight !== '') && 
                            <Typography className="iconAndText">
                                <Assignment style={{marginRight: '5px', marginBottom: '5px'}}/>
                                {dog.weight} kilo
                            </Typography>
                    }
                </CardContent>
                <CardActions>
                    <Button size="small" color="secondary" component={Link} to={"/dogDetail/"+dog.dogId}>
                        Meer info
                    </Button>
                </CardActions>
                </Card>
            </Grid>
        )
    }
};

export default DogCard;