import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import PhotoGallery from '../components/PhotoGallery';

import Wc from '@material-ui/icons/Wc';
import Cake from '@material-ui/icons/Cake';
import LocationOn from '@material-ui/icons/LocationOn';
import Facebook from '@material-ui/icons/Facebook';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Help from '@material-ui/icons/Help';
import Assignment from '@material-ui/icons/Assignment';

class DogDetail extends Component {
    state = {
        dogId: null,
        dog: null
    }
    componentDidMount(){
        const dogId = this.props.match.params.dogId
        this.setState({
            dogId: dogId
        });

        axios.get(`/dog/${dogId}`)
            .then((res) => {
                this.setState({
                    dog: res.data
                });
            })
            .catch((err) => console.log(err));
    }

    calculateAge(birthDateStr){
        const today = new Date();
        const birthDate = new Date(birthDateStr);
        var years = today.getFullYear() - birthDate.getFullYear();
        var months = today.getMonth() - birthDate.getMonth();
        if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())){
            years--;
            months += 12;
        }
        var text = "";
        if(years !== 0){
            text += ""+years+" jaar "
        }
        if(months !== 0){
            text += ""+months+" maand"
        }
        return text;
    }

    updateDog = (dog) => {
        this.setState({
            dog: dog
        });
    }

    deleteDog = () => {
        axios.delete(`/dog/${this.state.dog.dogId}`)
            .then(() => {
                this.props.history.push('/dogs');
            })
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <div>
                <Button component={Link} to="/dogs" color="inherit">&lt; Ga terug naar het overzicht</Button>
                {!this.state.dog && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '300px',
                        width: '100%'
                    }}>
                        <CircularProgress size={100} />
                    </div>
                )}
                {this.state.dog && (
                    <div>
                        <Typography component={'span'} variant="h3" align="center" className="notranslate">
                            {this.state.dog.name}
                            {this.props.authenticated && (
                                <div>
                                    <IconButton component={Link} to={`/editDog/${this.state.dog.dogId}`}><Create /></IconButton>
                                    <IconButton onClick={this.deleteDog}><Delete /></IconButton>
                                </div>
                            )}
                        </Typography>
                        {this.state.dog.state === "adopted" &&
                            <div className="adopted" style={{height: "70px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h1 className="adoptedTitle">Geadopteerd</h1>
                            </div>
                        }
                        {this.state.dog.state === "option" &&
                            <div className="option" style={{height: "70px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <h1 className="optionTitle">Gereserveerd</h1>
                            </div>
                        }
                        
                    <Grid container spacing={4} justify="center">
                        <Grid item xs={12} sm={6}>
                                <Typography className="iconAndText">
                                    <Wc style={{marginRight: '5px'}}/>
                                    {this.state.dog.gender === "female" ? "teef" :
                                        this.state.dog.gender === "male" ? "reu" : "onbekend"}
                                </Typography>
                                <Typography className="iconAndText">
                                    <Cake style={{marginRight: '5px', marginBottom: '5px'}}/>
                                    {this.calculateAge(this.state.dog.birthdate)}
                                </Typography>
                                <Typography className="iconAndText">
                                    <LocationOn style={{marginRight: '5px'}}/>
                                    {this.state.dog.location}
                                </Typography>
                                {
                                    (this.state.dog.weight && this.state.dog.weight !== '') && 
                                        <Typography className="iconAndText">
                                            <Assignment style={{marginRight: '5px'}}/>
                                            {this.state.dog.weight} kilo
                                        </Typography>
                                }
                                <Typography component={'span'} className="iconAndText">
                                    <Facebook style={{marginRight: '5px'}}/>
                                    <Typography>Bekijk <a href={this.state.dog.fbLink || 'https://www.facebook.com/profile.php?id=100068947600142'}>hier</a> onze Facebook pagina voor meer info</Typography>
                                </Typography>
                                <Typography className="iconAndText" style={{marginBottom: '30px'}}>
                                    <Help style={{marginRight: '5px'}}/>
                                    Stuur ons een berichtje op Facebook of een mail naar herosdogsmail@gmail.com voor meer info
                                </Typography>
                                {
                                    this.state.dog.description.split('\n').map((desc) => {
                                        return <Typography style={{wordWrap: "break-word"}}>{desc}</Typography>
                                    })
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <PhotoGallery primaryImage={this.state.dog.primaryImage} photos={this.state.dog.extraImages} dog={this.state.dog} authenticated={this.props.authenticated} updateDog={this.updateDog}/>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        )
    }
}

export default DogDetail;