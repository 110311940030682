import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import DogCard from '../components/DogCard';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Filter from '../components/Filter';

class Home extends Component {
    state = {
        dogs: null,
        allDogs: null
    };

    componentDidMount(){
        axios.get('/dogs')
            .then(res => {
                this.setState({
                    dogs: this.sortDogs(res.data),
                    allDogs: this.sortDogs(res.data)
                });
            })
            .catch(err => console.log(err));
    };

    sortDogs(dogs){
        dogs.sort((a,b) => {
            return new Date(a.createdAt) > new Date(b.createdAt)? -1 : 1; 
        });
        return dogs.sort((a,b) => {
            if(a.state === b.state) return 0;
            if(a.state === "adoptable") return -1;
            if(b.state === "adoptable") return 1;
            if(a.state === "option" && b.state === "adopted") return -1;
            else return 1;
        });
    }

    changeDogList = (dogs) => {
        this.setState({ dogs: this.sortDogs(dogs) });
    }

    render() {
        return (
            <div>
                <Container className="cardGrid" maxWidth="md">
                { this.props.authenticated && (
                    <Button style={{marginBottom: "20px"}} variant="contained" color="secondary" component={Link} to="/addDog">Voeg een nieuwe hond toe</Button>
                )}
                {this.state.dogs && 
                            <Filter dogs={this.state.allDogs} changeDogList={this.changeDogList} />
                    }
                <Grid container spacing={4}>
                    {!this.state.dogs && (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '300px',
                            width: '100%'
                        }}>
                            <CircularProgress size={100} />
                        </div>
                    )}
                    {this.state.dogs && this.state.dogs.map((dog) => (
                            <DogCard key={dog.dogId} dog={dog} />
                        )) 
                    }
                </Grid>
                </Container>
            </div>
        )
    }
}

export default Home;