import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import logo from '../pictures/logo.jpg';

class HamburgerMenu extends Component {
    state = {
        open: false
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    signOut = () => {
        localStorage.removeItem("FBIdToken");
        this.setState({ open: false });
        window.location.reload();
    };

    render() {
        return (
            <div>
                <AppBar position="fixed" color="secondary">
                    <Toolbar style={{ display: "flex", justifyContent: "space-between"}}>
                        <IconButton component={Link} to="/">
                            <Avatar alt="home" src={logo} />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={this.handleDrawerOpen}
                            style={{ display: this.state.open? "none" : ""}}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={this.state.open}
                >
                    <div className="drawerHeader">
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Divider color="secondary" />
                    <Button color="secondary" onClick={this.handleDrawerClose} component={Link} to="/" style={{margin: "5px"}}>Welkom</Button>
                    <Button color="secondary" onClick={this.handleDrawerClose} component={Link} to="/dogs" style={{margin: "5px"}}>Honden</Button>
                    <Button color="secondary" onClick={this.handleDrawerClose} component={Link} to="/aboutUs" style={{margin: "5px"}}>Over ons</Button>
                    <Button color="secondary" onClick={this.handleDrawerClose} component={Link} to="/info" style={{margin: "5px"}}>Info</Button>
                    <Button color="secondary" onClick={this.handleDrawerClose} component={Link} to="/supportUs" style={{margin: "5px"}}>Steun ons</Button>
                    {this.props.authenticated && (
                        <Button variant="contained" color="primary" onClick={this.signOut} style={{margin: "5px"}}>Afmelden als admin</Button>
                    )}
                </Drawer>
            </div>
        )
    }
}

export default HamburgerMenu;
