import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class welcome extends Component {
    render() {
        return (
            <div className="welcome">
                <img style={{
                    height: '150px',
                    width: 'auto',
                    marginBottom: '20px'
                }} 
                    src="https://firebasestorage.googleapis.com/v0/b/herosdogs-42393.appspot.com/o/banner.jpg?alt=media" alt="Heros Dogs"/>
                <Typography variant="h3" align="center">Welkom bij vzw Heros Dogs!</Typography>
                <Typography>
                    <br />
                    Op deze website vind je een overzicht van onze honden die ter adoptie zijn en vind je meer informatie over het adopteren van deze honden.
                    <br /><br />
                    Dit zijn vooral hondjes afkomstig uit Roemenië maar we hopen dat jij net zoals ons het belang in ziet dat ook deze hondjes een gouden mandje verdienen. 
                    <br /><br />
                    Op zoek naar een hondje om jouw gouden mandje op te vullen? Kijk dan snel naar de hondjes die momenteel ter beschikking zijn!<br />
                </Typography>
                <Button component={Link} to="/dogs">Bekijk snel onze honden!</Button>
                <img style={{
                    height: '200px',
                    width: 'auto',
                    marginBottom: '20px'
                }} 
                    src="https://firebasestorage.googleapis.com/v0/b/herosdogs-42393.appspot.com/o/adoptLogo.png?alt=media" alt="adopt dont shop"/>
            </div>
        )
    }
};

export default welcome;
