import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import logo from '../pictures/logo.jpg';

class NavBar extends Component {
    signOut = () => {
        localStorage.removeItem("FBIdToken");
        window.location.reload();
    };

    render() {
        return (
            <AppBar position="fixed" color="secondary">
                <Toolbar>
                    <IconButton component={Link} to="/">
                        <Avatar alt="home" src={logo} />
                    </IconButton>
                    <Button color="inherit" component={Link} to="/">Welkom</Button>
                    <Button color="inherit" component={Link} to="/dogs">Honden</Button>
                    <Button color="inherit" component={Link} to="/aboutUs">Over ons</Button>
                    <Button color="inherit" component={Link} to="/info">Adoptie procedure</Button>
                    <Button color="inherit" component={Link} to="/supportUs">Steun ons</Button>
                    {this.props.authenticated && (
                        <Button variant="contained" color="primary" onClick={this.signOut}>Afmelden als admin</Button>
                    )}
                </Toolbar>
            </AppBar>
        )
    }
}

export default NavBar;
