import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Facebook from '@material-ui/icons/Facebook';

class Footer extends Component {
    render() {
        const year = new Date().getFullYear();
        return (
            <div style={{
                height: '200px', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center'
                }}>
                <Typography variant="h4">Heros Dogs vzw</Typography>
                <IconButton href="https://www.facebook.com/Heros-Dogs-vzw-Honden-Ter-Adoptie-403720170119358">
                    <Facebook />
                </IconButton>
                <Typography >herosdogsmail@gmail.com</Typography>
                <Typography >&copy; {year} Heros Dogs vzw</Typography>
            </div>
        )
    }
};

export default Footer;
