import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

class info extends Component {
    render() {
        return (
            <div className="info">
                <Typography variant="h5" style={{marginBottom: '20px'}}>Hoe verloopt een adoptie?</Typography>
                <Typography className="iconAndText" style={{marginBottom: '20px'}}>
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Zie jij een hondje die jou interesseert en die jij eventueel een gouden mandje wilt geven? Stuur ons dan ofwel een berichtje via de Facebook pagina ofwel een mailtje naar ons e-mailadres. Via deze kanalen kun je alle info die gekend is over het hondje te weten komen en zal je verder begeleid worden in het adoptieproces.
                </Typography>
                <Typography className="iconAndText" style={{marginBottom: '20px'}}>
                    <FavoriteBorder color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Ben je oprecht geïnteresseerd in het adopteren van het hondje? Dan zal je een vragenlijst ontvangen die je zo correct mogelijk moet invullen zodat wij kunnen kijken of jij een geschikt baasje bent voor dit hondje want een adoptie werkt natuurlijk in twee richtingen.
                </Typography>
                <Typography className="iconAndText"  style={{marginBottom: '20px'}}>
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Na het vergelijken van de vragenlijst met de noden van het hondje, zullen wij beslissen of dit een goed match is. Is dit het geval, dan zal er een afspraak gemaakt worden voor een huisbezoek. Er zal iemand van ons langskomen bij jou om te kijken of jouw woning geschikt is voor het hondje.
                </Typography>
                <Typography className="iconAndText" style={{marginBottom: '20px'}}>
                    <FavoriteBorder color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Na het gunstig beëindigen van al deze stappen kan de echte adoptie beginnen! Hiervoor is er een adoptie bijdrage nodig.
                    De adoptie bijdrage bedraagt: <br />
                    &nbsp;&nbsp;&nbsp;voor honden tot en met 8 jaar: 395 euro <br />
                    &nbsp;&nbsp;&nbsp;voor honden vanaf 9 jaar: 245 euro <br />
                    In deze adoptie bijdrage zit inbegrepen : testen, vaccinaties, sterilisatie/castratie ( als hun leeftijd dit toelaat ) ontvlooiing, ontworming, transport vanuit Roemenië, chip en Europees paspoort.
                </Typography>
                <Typography className="iconAndText" style={{marginBottom: '20px'}}>
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Het is verplicht dat je de eerste maanden de hond uitlaat met een driepunts veiligheidstuigje, een halsband en een dubbele leiband aan het tuigje en aan de halsband. Dit om te voorkomen dat de hond zou kunnen weglopen tot de hond meer gewend is aan het leven bij jou. Het is trouwens altijd beter om jouw hond met dubbele beveiliging buiten te laten. <br />
                    Het is mogelijk om dit bij ons aan te kopen. Een driepunts veiligheidstuigje, een halsband en een dubbele leiband kosten tesamen €35 inclusief verzendkosten. Op deze manier zijn jullie helemaal klaar om het hondje te ontvangen!
                </Typography>
                <Typography className="iconAndText" style={{marginBottom: '20px'}}>
                    <FavoriteBorder color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Het is eindelijk zo ver! Je hondje kan zo snel als mogelijk vervoerd worden naar België of Nederland waar jouw gouden mandje al klaar staat! Nu kan het avontuur pas echt beginnen.
                </Typography>
                <Typography component={'span'}  className="iconAndText" style={{marginBottom: '20px'}}>
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    <Typography>Voor meer informatie en vragen, bezoek onze <a href="https://www.facebook.com/Heros-Dogs-vzw-Honden-Ter-Adoptie-403720170119358"> Facebook-pagina</a> en stuur ons een berichtje</Typography>
                </Typography>
            </div>
        )
    }
}

export default info;