import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

class AddEditDog extends Component {
    constructor(){
        super();
        this.state = {
            name: '',
            birthDate: '',
            description: '',
            gender: '',
            location: '',
            state: '',
            fbLink: '',
            weight: '',
            size: '',
            loading: false
        }
    }

    componentDidMount(){
        const dogId = this.props.match.params.dogId
        if(dogId){
            this.setState({
                dogId: dogId
            });

            axios.get(`/dog/${dogId}`)
                .then((res) => {
                    this.setState({
                        name: res.data.name,
                        birthDate: res.data.birthdate.substr(0,10),
                        description: res.data.description,
                        gender: res.data.gender,
                        location: res.data.location,
                        state: res.data.state,
                        fbLink: res.data.fbLink,
                        primaryImage: res.data.primaryImage,
                        extraImages: res.data.extraImages,
                        weight: res.data.weight || '',
                        size: res.data.size || ''
                    });
                })
                .catch((err) => console.log(err));
        }
        
    }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const dogData = {
            name: this.state.name,
            birthdate: this.state.birthDate,
            description: this.state.description,
            gender: this.state.gender,
            location: this.state.location,
            state: this.state.state,
            fbLink: this.state.fbLink,
            weight: this.state.weight,
            size: this.state.size
        };
        const fbIdToken = localStorage.getItem("FBIdToken");
        if(fbIdToken){
            axios.post('/dog', dogData)
            .then(res => {
                this.setState({ loading: false });
                this.props.history.push(`/dogDetail/${res.data.dogId}`);
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    errors: { error: 'Er is iets misgelopen, probeer opnieuw' },
                    loading: false
                });
            });
        }
        else {
            this.setState({
                errors: { error: 'Geen admin' },
                loading: false
            });
            this.props.history.push('/');
            window.location.reload();
        }
    }

    handleEdit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const dogData = {
            name: this.state.name,
            birthdate: this.state.birthDate,
            description: this.state.description,
            gender: this.state.gender,
            location: this.state.location,
            state: this.state.state,
            fbLink: this.state.fbLink,
            primaryImage: this.state.primaryImage,
            extraImages: this.state.extraImages,
            weight: this.state.weight,
            size: this.state.size
        };
        const fbIdToken = localStorage.getItem("FBIdToken");
        if(fbIdToken && this.state.dogId){
            axios.post(`/dog/${this.state.dogId}`, dogData)
            .then(res => {
                this.setState({ loading: false });
                this.props.history.push(`/dogDetail/${this.state.dogId}`);
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    errors: { error: 'Er is iets misgelopen, probeer opnieuw' },
                    loading: false
                });
            });
        }
        else {
            this.setState({
                errors: { error: 'Geen admin' },
                loading: false
            });
            this.props.history.push('/');
            window.location.reload();
        }
    }

    render() {
        return (
            <Container maxWidth="xs">
                {!this.state.dogId ? (
                    <Button component={Link} to="/" color="inherit">&lt; Annuleer en ga terug naar het overzicht</Button>  
                ) : (
                    <div>
                        <Button component={Link} to={`/dogDetail/${this.state.dogId}`} color="inherit">&lt; Annuleer aanpassing en ga terug naar het overzicht</Button>
                        <Typography variant="h2">Hond aanpassen</Typography>
                    </div>
                )}
                <form noValidate onSubmit={this.state.dogId? this.handleEdit : this.handleSubmit} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {this.state.loading? (
                        <CircularProgress />
                    ) : (
                        <div>
                            <TextField
                                id="name"
                                name="name"
                                label="Naam hond"
                                value={this.state.name}
                                onChange={this.handleChange}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                autoFocus
                            />
                        <TextField
                            id="birthDate"
                            label="Geboorte datum"
                            name="birthDate"
                            type="date"
                            value={this.state.birthDate}
                            onChange={this.handleChange}
                            variant="outlined"
                            margin="normal"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            required
                            fullWidth
                        />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Geslacht</FormLabel>
                            <RadioGroup aria-label="gender" name="gender" value={this.state.gender} onChange={this.handleChange}>
                                <FormControlLabel value="female" control={<Radio />} label="Teef" />
                                <FormControlLabel value="male" control={<Radio />} label="Reu" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                                id="weight"
                                name="weight"
                                label="Gewicht"
                                value={this.state.weight}
                                onChange={this.handleChange}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Grootte</FormLabel>
                            <RadioGroup aria-label="size" name="size" value={this.state.size} onChange={this.handleChange}>
                                <FormControlLabel value="small" control={<Radio />} label="Klein" />
                                <FormControlLabel value="medium" control={<Radio />} label="Middelmaat" />
                                <FormControlLabel value="large" control={<Radio />} label="Groot" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                                id="location"
                                name="location"
                                label="Huidige locatie hond"
                                value={this.state.location}
                                onChange={this.handleChange}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                            />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup aria-label="state" name="state" value={this.state.state} onChange={this.handleChange}>
                                <FormControlLabel value="adoptable" control={<Radio />} label="Vrij voor adoptie" />
                                <FormControlLabel value="option" control={<Radio />} label="Gereserveerd" />
                                <FormControlLabel value="adopted" control={<Radio />} label="Reeds geadopteerd" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                                id="fbLink"
                                name="fbLink"
                                label="Link naar Facebook"
                                value={this.state.fbLink}
                                onChange={this.handleChange}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                            />
                        <TextField
                                id="description"
                                name="description"
                                label="Beschrijving"
                                value={this.state.description}
                                onChange={this.handleChange}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                multiline
                            />
                        </div>
                    )}
                    {!this.state.dogId ? (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={this.state.loading}
                        >
                            Voeg hond toe
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={this.state.loading}
                        >
                            Pas hond aan
                        </Button>
                    )}
                    
                </form>
            </Container>
        )
    }
}

export default AddEditDog;