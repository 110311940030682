import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

import Favorite from '@material-ui/icons/Favorite';

class supportUs extends Component {
    render() {
        return (
            <div className="info">
                <Typography variant="h5" className="iconAndText" style={{marginBottom: '20px'}}>
                    Kan jij momenteel geen hondje helpen door hem een gouden mandje aan te bieden? Geen probleem, er zijn nog andere manieren om ons en deze hondjes te steunen!
                </Typography>
                <Typography  className="iconAndText" style={{marginBottom: '20px'}}>
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Wil je een donatie doen? Dit kan op ons rekeningnummer Helping Romanian Stray Dogs vzw: BE68 0018 3595 1534
                </Typography>
            </div>
        )
    }
};

export default supportUs;