import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import jwtDecode from 'jwt-decode';
import AuthRoute from './util/AuthRoute';
import axios from 'axios';
import MediaQuery from 'react-responsive';

import Home from './pages/home';
import login from './pages/login';
import info from './pages/info';
import DogDetail from './pages/dogDetail';
import AddEditDog from './pages/addEditDog';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import welcome from './pages/welcome';
import aboutUs from './pages/aboutUs';
import supportUs from './pages/supportUs';
import HamburgerMenu from './components/HamburgerMenu';

axios.defaults.baseURL = 'https://europe-west1-herosdogs-42393.cloudfunctions.net/api';
//axios.defaults.baseURL = 'http://localhost:5000/herosdogs-42393/europe-west1/api';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#dbdbdb",
      light: "#f5f5f5",
      dark: "#5c5c5c"
    },
    secondary: {
      main: "#db0000"
    }
  }
});

let authenticated;
const token = localStorage.getItem("FBIdToken");
if(token) {
  const decodedToken = jwtDecode(token);
  if(decodedToken.exp * 1000 < Date.now()) {
    localStorage.removeItem("FBIdToken");
    authenticated=false;
  } else {
    authenticated=true;
    axios.defaults.headers.common['Authorization'] = token;
  }
} else {
  authenticated= false;
}

function App() {
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <Router>
          <MediaQuery minWidth={800}>
            <NavBar authenticated={authenticated}/>
          </MediaQuery>
          <MediaQuery maxWidth={800}>
            <HamburgerMenu authenticated={authenticated}/>
          </MediaQuery>
          <div className="container">
            <Switch>
              <Route exact path="/" component={welcome}/>
              <Route exact path="/dogs" render={(props) => <Home {...props} authenticated={authenticated} /> }/>
              <Route exact path="/aboutUs" component={aboutUs}/>
              <Route exact path="/info" component={info}/>
              <Route exact path="/supportUs" component={supportUs}/>
              <Route exact path="/dogDetail/:dogId" render={(props) => <DogDetail {...props} authenticated={authenticated} /> }/>
              <Route exact path="/login" component={login}/>
              <AuthRoute exact path="/addDog" component={AddEditDog} authenticated={authenticated}/>
              <AuthRoute exact path="/editDog/:dogId" component={AddEditDog} authenticated={authenticated}/>
            </Switch>
          </div>
          <Footer />
        </Router>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
