import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

const ages = [ 'Toon alle', 'Jonger dan 1 jaar', 'Tussen 1 jaar en 10 jaar', 'Ouder dan 10 jaar'];
const sizes = [ 'Toon alle', 'Klein', 'Middelmaat', 'Groot'];
const genders = [ 'Toon alle', 'Teef', 'Reu'];

class Filter extends Component {
    state = {
        age: 'Toon alle',
        size: 'Toon alle',
        gender: 'Toon alle',
        resultNumbers: null
    }

    componentDidMount = () => {
        var filters = sessionStorage.getItem('filters');
        if(filters && filters !== 'undefined') {
            filters = JSON.parse(filters);
            this.setState({
                age: filters.age,
                size: filters.size,
                gender: filters.gender,
                resultNumbers: filters.resultNumbers
            });
            if(this.props.dogs) {
                this.props.changeDogList(this.filterSize(
                this.filterGender(
                    this.filterAge(this.props.dogs, filters.age),
                    filters.gender),filters.size));
            }
        }
    }

    calculateAge(birthDateStr){
        const today = new Date();
        const birthDate = new Date(birthDateStr);
        var years = today.getFullYear() - birthDate.getFullYear();
        var months = today.getMonth() - birthDate.getMonth();
        if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())){
            years--;
        }
        return years;
    }

    updateLocalStorage = (filters) => {
        sessionStorage.setItem('filters', JSON.stringify(filters));
    }

    changeFilterAge = (event) => {
        const filteredDogs = this.filterSize(
            this.filterGender(
                this.filterAge(this.props.dogs, event.target.value),
                this.state.gender),this.state.size);
        this.setState({
            age: event.target.value,
            resultNumbers: filteredDogs.length
          });
        this.updateLocalStorage({
            ...this.state,
            age: event.target.value,
            resultNumbers: filteredDogs.length
        });
        this.props.changeDogList(filteredDogs);
    }

    changeFilterGender = (event) => {
        const filteredDogs = this.filterSize(
            this.filterGender(
                this.filterAge(this.props.dogs, this.state.age),
                event.target.value),this.state.size);
        this.setState({
            gender: event.target.value,
            resultNumbers: filteredDogs.length
          });
        this.updateLocalStorage({
            ...this.state,
            gender: event.target.value,
            resultNumbers: filteredDogs.length
        });
        this.props.changeDogList(filteredDogs);
    }

    changeFilterSize = (event) => {
        const filteredDogs = this.filterSize(
            this.filterGender(
                this.filterAge(this.props.dogs, this.state.age),
                this.state.gender),event.target.value);
        this.setState({
            size: event.target.value,
            resultNumbers: filteredDogs.length
          });
        this.updateLocalStorage({
            ...this.state,
            size: event.target.value,
            resultNumbers: filteredDogs.length
        });
        this.props.changeDogList(filteredDogs);
    }

    filterAge = (dogs, ageFilter) => {
        var min = 0;
        var max = 100;
        if(ageFilter === 'Toon alle'){
            min = 0;
            max = 100;
        } else if (ageFilter === 'Jonger dan 1 jaar') {
            min = 0;
            max = 1;
        } else if (ageFilter === 'Tussen 1 jaar en 10 jaar') {
            min = 1;
            max = 10;
        } else if (ageFilter === 'Ouder dan 10 jaar') {
            min = 10;
            max = 100;
        }
        const filteredDogs = dogs.filter((dog) => {
            const age = this.calculateAge(dog.birthdate);
            return age >= min && age < max;
        });
        return filteredDogs;
    }

    filterGender = (dogs, genderFilter) => {
        var gender = '';
        if(genderFilter === 'Teef'){
            gender = 'female';
        } else if(genderFilter === 'Reu'){
            gender = 'male';
        }
        const filteredDogs = dogs.filter((dog) => {
            return gender === '' || dog.gender === gender;
        });
        return filteredDogs;
    }

    filterSize = (dogs, sizeFilter) => {
        var size = '';
        if(sizeFilter === 'Klein'){
            size = 'small';
        } else if(sizeFilter === 'Middelmaat'){
            size = 'medium';
        } else if(sizeFilter === 'Groot'){
            size = 'large';
        }
        const filteredDogs = dogs.filter((dog) => {
            return size === '' || dog.size === size;
        });
        return filteredDogs;
    }

    render() {
        return (
            <form style={{ marginBottom: '15px', border: '3px solid #db0000', padding: '10px', borderRadius: '25px'}}>
                <Typography variant='h5' style={{ marginBottom: '10px'}}>Filter resultaten ({this.state.resultNumbers || (this.props.dogs && this.props.dogs.length)}):</Typography>
                <TextField
                    id='gender'
                    name='gender'
                    select
                    label="Geslacht"
                    value={this.state.gender}
                    onChange={this.changeFilterGender}
                    variant='outlined'
                    color="secondary"
                    size='small'
                    style={{ width: '130px', margin: '5px'}}>
                        {
                            genders.map((gender) => (
                                <MenuItem key={gender} value={gender}>
                                    {gender}
                                </MenuItem>
                            ))
                        }
                </TextField>
                <TextField
                    id='size'
                    name='size'
                    select
                    label="Grootte"
                    value={this.state.size}
                    onChange={this.changeFilterSize}
                    variant='outlined'
                    color="secondary"
                    size='small'
                    style={{ width: '140px', margin: '5px'}}>
                        {
                            sizes.map((size) => (
                                <MenuItem key={size} value={size}>
                                    {size}
                                </MenuItem>
                            ))
                        }
                </TextField>
                <TextField
                    id='age'
                    name='age'
                    select
                    label="Leeftijd"
                    value={this.state.age}
                    onChange={this.changeFilterAge}
                    variant='outlined'
                    color="secondary"
                    size='small'
                    style={{ width: '220px', margin: '5px'}}>
                        {
                            ages.map((age) => (
                                <MenuItem key={age} value={age}>
                                    {age}
                                </MenuItem>
                            ))
                        }
                </TextField>
            </form>
        )
    }
};

export default Filter;
