import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component: Component, authenticated, ...rest}) => (
    <Route {...rest}
        render={(props) => authenticated === false ? 
            <Redirect to='/'/> : 
            <Component {...props} authenticated={authenticated} />}
    />
)

export default AuthRoute;