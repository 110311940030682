import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

class aboutUs extends Component {
    render() {
        return (
            <div className="info">
                <Typography  className="iconAndText" style={{marginBottom: '20px'}}>
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Wij zijn 'Helping Romanian Stray Dogs vzw / Heros Dogs vzw', een vzw die zich inzet om Roemeense straathondjes een gouden mandje te geven.
                </Typography>
                <Typography  className="iconAndText" style={{marginBottom: '20px'}}>
                    <FavoriteBorder color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Wij werken aan de hand van een vragenlijst en een huisbezoek.
                </Typography>
                <Typography  className="iconAndText">
                    <Favorite color="secondary" style={{marginRight: '5px'}} fontSize="small"/>
                    Voor meer informatie kan je altijd naar onze Facebook pagina gaan kijken en ons een berichtje sturen:
                </Typography>
                <Typography  style={{marginBottom: '20px', marginLeft:'40px'}}><a href="https://www.facebook.com/Heros-Dogs-vzw-Honden-Ter-Adoptie-403720170119358">www.facebook.com/Heros-Dogs-vzw-Honden-Ter-Adoptie</a></Typography>
            </div>
        )
    }
};

export default aboutUs;