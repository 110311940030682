import React, { Component } from 'react';
import axios from 'axios';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

class login extends Component {
    constructor(){
        super();
        this.state = {
            email: '',
            password: '',
            loading: false,
            errors: {}
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        axios.post('/login', userData)
            .then(res => {
                localStorage.setItem('FBIdToken',`Bearer ${res.data.token}`);
                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
                this.setState({ loading: false });
                this.props.history.push('/');
                window.location.reload();
            })
            .catch((err) => {
                this.setState({
                    errors: { error: 'Verkeerd email adres of wachtwoord' },
                    loading: false
                });
            });
    }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
    };

    render() {
        return (
            <Container maxWidth="xs">
                <Typography component="h1" variant="h5">
                Log in als admin
                </Typography>
                <form noValidate onSubmit={this.handleSubmit}>
                    <TextField
                        id="email"
                        name="email"
                        type="email"
                        label="Email Adres"
                        value={this.state.email}
                        onChange={this.handleChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        error={this.state.errors.error? true : false}
                    />
                    <TextField
                        id="password"
                        name="password"
                        type="password"
                        label="Wachtwoord"
                        value={this.state.password}
                        onChange={this.handleChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={this.state.errors.error? true : false}
                    />
                    {this.state.errors.error && (
                        <Typography variant="body2" color="secondary">
                            {this.state.errors.error}
                        </Typography>
                        )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={this.state.loading}
                    >
                        Log in
                        {this.state.loading && (
                            <CircularProgress />
                        )}
                    </Button>
                </form>
            </Container>
        )
    }
}

export default login;