import React, { Component } from 'react';
import axios from 'axios';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Delete from '@material-ui/icons/Delete';

import '../App.css';

class PhotoGallery extends Component {
    state = {
        loading: false
    }

    uploadExtraPhoto = () => {
        const fileInput = document.getElementById('imageInputExtra');
        fileInput.click();
    }

    handleExtraImageAdd = (event) => {
        this.setState({ loading: true });
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        axios
            .post(`/dog/extraImage/${this.props.dog.dogId}`, formData)
            .then((res) => {
                this.props.updateDog({
                    dogId: this.props.dog.dogId,
                    name: this.props.dog.name,
                    birthdate: this.props.dog.birthdate,
                    description: this.props.dog.description,
                    gender: this.props.dog.gender,
                    location: this.props.dog.location,
                    state: this.props.dog.state,
                    fbLink: this.props.dog.fbLink,
                    primaryImage: this.props.dog.primaryImage,
                    extraImages: [...this.props.dog.extraImages, res.data.newUrl]
                })
                this.setState({ loading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false });
            });
    };

    handlePrimaryImageChange = (event) => {
        this.setState({ loading: true });
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image, image.name);
        const filename = this.props.primaryImage.substring(this.props.primaryImage.lastIndexOf('/')+1,this.props.primaryImage.lastIndexOf('?'));
        if(filename.includes('no-picture')) {
            axios
                .post(`/dog/primaryImage/${this.props.dog.dogId}`, formData)
                .then((res) => {
                    this.props.updateDog({
                        dogId: this.props.dog.dogId,
                        name: this.props.dog.name,
                        birthdate: this.props.dog.birthdate,
                        description: this.props.dog.description,
                        gender: this.props.dog.gender,
                        location: this.props.dog.location,
                        state: this.props.dog.state,
                        fbLink: this.props.dog.fbLink,
                        primaryImage: res.data.newUrl,
                        extraImages: this.props.dog.extraImages
                    })
                    this.setState({ loading: false });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading: false });
                });
        } else {
            axios.delete(`/image/${filename}`)
            .then(() => {
                axios
                .post(`/dog/primaryImage/${this.props.dog.dogId}`, formData)
                .then((res) => {
                    this.props.updateDog({
                        dogId: this.props.dog.dogId,
                        name: this.props.dog.name,
                        birthdate: this.props.dog.birthdate,
                        description: this.props.dog.description,
                        gender: this.props.dog.gender,
                        location: this.props.dog.location,
                        state: this.props.dog.state,
                        fbLink: this.props.dog.fbLink,
                        primaryImage: res.data.newUrl,
                        extraImages: this.props.dog.extraImages
                    })
                    this.setState({ loading: false });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading: false });
                });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false });
            });
        }
    };

    uploadProfilePhoto = () => {
        const fileInput = document.getElementById('imageInput');
        fileInput.click();
    };

    deleteExtraPhoto = (photo) => {
        this.setState({ loading: true });
        const newExtraImagesList = this.props.photos.filter((p) => p !== photo);
        const dogData = {
            dogId: this.props.dog.dogId,
            name: this.props.dog.name,
            birthdate: this.props.dog.birthdate,
            description: this.props.dog.description,
            gender: this.props.dog.gender,
            location: this.props.dog.location,
            state: this.props.dog.state,
            fbLink: this.props.dog.fbLink,
            primaryImage: this.props.dog.primaryImage,
            extraImages: newExtraImagesList
        };
        const filename = photo.substring(photo.lastIndexOf('/')+1,photo.lastIndexOf('?'));
        const fbIdToken = localStorage.getItem("FBIdToken");
        if(fbIdToken && this.props.dog){
            axios.delete(`/image/${filename}`)
                .then(() => {
                    axios.post(`/dog/${this.props.dog.dogId}`, dogData)
                        .then(res => {
                            this.props.updateDog(dogData);
                            this.setState({ loading: false });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({ loading: false });
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading: false });
                });            
        }
        else {
            this.props.history.push('/');
            window.location.reload();
        }
    };
    
    render() {
        const photos = this.props.photos;
        return (
           <div className="rootGallery">
               {
                   this.state.loading? (
                        <CircularProgress size={90} />
                   ) : (
                        <div style={{ height: '100%', width: '100%'}}>
                            {this.props.authenticated && (
                                <div>
                                    <input
                                            type="file"
                                            id="imageInput"
                                            hidden="hidden"
                                            onChange={this.handlePrimaryImageChange}
                                        />
                                    <Button onClick={this.uploadProfilePhoto} variant="outlined" style={{ marginRight: '10px', marginBottom: '20px'}}>
                                        Verander voorpagina foto
                                    </Button>
                                    <input
                                        type="file"
                                        id="imageInputExtra"
                                        hidden="hidden"
                                        onChange={this.handleExtraImageAdd}
                                    />
                                    <Button onClick={this.uploadExtraPhoto} variant="outlined" style={{ marginBottom: '20px'}}>
                                        Voeg een extra foto toe
                                    </Button>
                                </div>
                            )}
                            <GridList className="gridGallery" cellHeight={260}>
                                    <GridListTile className="galleryGridListTile">
                                        <img style={{ zIndex: '-1' }} src={this.props.primaryImage} alt={"Primary"} />
                                    </GridListTile>
                                {photos.map((photo, index) => (
                                    <GridListTile key={photo}  className="galleryGridListTile">
                                        {this.props.authenticated && (
                                            <IconButton onClick={() => this.deleteExtraPhoto(photo)} color="secondary">
                                                <Delete 
                                                        style={{
                                                            padding: '1px',
                                                            border: '1px solid white',
                                                            backgroundColor: 'white',
                                                            borderRadius: '50%'
                                                        }}
                                                    />
                                            </IconButton>
                                        )}
                                        <img style={{ zIndex: '-1' }} src={photo} alt={"Photo "+index} />
                                    </GridListTile>
                                ))}
                            </GridList>
                        </div>
                   )
               }
           </div>
        )
    }
}

export default PhotoGallery;
